<template>
  <b-overlay :show="loading" rounded="sm" no-fade>
    <b-row class="justify-content-center">
      <b-col cols="12" xl="2" lg="2" md="2" sm="12">
        <b-button class="mb-25" @click="quickFilter('today')" size="sm" variant="dark" block>
          Today
        </b-button>
      </b-col>

      <b-col cols="12" xl="2" lg="2" md="2" sm="12">
        <b-button class="mb-25" @click="quickFilter('yesterday')" size="sm" variant="dark" block>
          Yesterday
        </b-button>
      </b-col>

      <b-col cols="12" xl="2" lg="2" md="2" sm="12">
        <b-button class="mb-25" @click="quickFilter('thisweek')" size="sm" variant="dark" block>
          This Week
        </b-button>
      </b-col>

      <b-col cols="12" xl="2" lg="2" md="2" sm="12">
        <b-button class="mb-25" @click="quickFilter('lastweek')" size="sm" variant="dark" block>
          Last Week
        </b-button>
      </b-col>

      <b-col cols="12" xl="2" lg="2" md="2" sm="12">
        <b-button class="mb-25" @click="quickFilter('thismonth')" size="sm" variant="dark" block>
          This Month
        </b-button>
      </b-col>

      <b-col cols="12" xl="2" lg="2" md="2" sm="12">
        <b-button class="mb-25" @click="quickFilter('lastmonth')" size="sm" variant="dark" block>
          Last Month
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
        <b-alert variant="secondary" class="mt-1" show>
          <div class="alert-body">
            <span class="text-center h5"><strong>Total Duration: </strong> {{ hoursFormat(totalClient) }}</span>
          </div>
        </b-alert>
      </b-col>

      <b-col cols="12" xl="6" md="6" sm="12">
        <input class="dataRange mt-1" type="text" ref="flatpickrInput" :value="rangeDate" />
      </b-col>
    </b-row>

    <b-row v-if="summaryReports.length > 0">
      <b-col cols="12" xl="12" md="12">
        <app-collapse type="margin">
          <app-collapse-item class="overflow-auto" :id="'collapse -'" :title="employee.userName + '  -  ' + hoursFormat(employee.totalDuration)" v-for="employee in summaryReports" :key="employee.userId">
            <b-table v-if="employee.entries.length > 0" striped hover ref="refUserListTable" class="position-relative" :items="employee.entries" filter-debounce="250" responsive :small="true" head-variant="dark" :fields="tableColumns" show-empty empty-text="No matching records found">
              <template #cell(date)="data">
                <small>{{ formatDateTime(data.item.timeInterval.start) }}</small>
                <br />
                <small>{{ formatDateTime(data.item.timeInterval.end) }}</small>
              </template>

              <template #cell(description)="data">
                {{ data.value }}
              </template>
              <template #cell(timeInterval)="data">
                {{ hoursFormat(data.value.duration) }}
              </template>
              <template #cell(billable)="data">
                <b-badge pill v-if="data.value" variant="success">Billable</b-badge>
                <b-badge pill v-else variant="secondary"> Non Billable </b-badge>
              </template>
              <template #cell(tags)="data">
                <b-badge pill v-for="tag in data.value" :key="tag.index" small variant="info">{{ tag.name }}</b-badge>
              </template>
            </b-table>
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col cols="12" xl="12" md="12" class="no-result-message">No Result </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import store from '@/store';
import vSelect from 'vue-select';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import { required, email } from '@validations';
import router from '@/router';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { convertToSecondISO, convertToFirstISO, hoursFormat, getTodayDate, getYesterdayDate, getThisWeekDate, getLastWeekDate, getThisMonthDate, getLastMonthDate, formatDateTime } from '../helpers';

export default {
  components: {
    vSelect,
    ToastificationContent,
    Treeselect,
    AppCollapseItem,
    AppCollapse,
    flatPickr,
  },

  props: {
    clientClockifyId: String,
    projectClockifyId: String,
  },

  computed: {},

  created() {
    const today = this.getTodayDate();
    const yesterday = this.getYesterdayDate();
    this.rangeDate = String(yesterday) + ' to ' + String(today);

    this.getClientIdProjectIdReports();
  },

  data() {
    return {
      dateRangeStart: null,
      dateRangeEnd: null,
      summaryReports: [],
      selectedProjects: [],

      projects: [],
      totalClient: 0,
      showOptions: ['Active', 'Archived'],
      totalTime: 0,
      loading: false,
      totalEntries: 0,
      rangeDate: null,
      selectedOption: 'Active',
      tableColumns: [
        { key: 'date', label: 'Date', class: 'text-center' },
        { key: 'description', label: 'Description', class: 'text-center' },
        { key: 'timeInterval', label: 'Duration', class: 'text-center' },
        { key: 'billable', label: 'Billable', class: 'text-center' },
        { key: 'tags', label: 'Tags', class: 'text-center' },
      ],
    };
  },

  mounted() {
    flatpickr(this.$refs.flatpickrInput, {
      dateFormat: 'd-m-Y',
      mode: 'range',
      locale: {
        firstDayOfWeek: 1,
      },
      onClose: (selectedDates, dateStr, instance) => {
        if (!instance.isOpen) {
          this.rangeDate = dateStr;
          this.getClientIdProjectIdReports();
        }
      },
    });
  },

  methods: {
    hoursFormat,
    getTodayDate,
    getYesterdayDate,
    getThisWeekDate,
    getLastWeekDate,
    getThisMonthDate,
    getLastMonthDate,
    formatDateTime,
    convertToFirstISO,
    convertToSecondISO,

    quickFilter(val) {
      switch (val) {
        case 'today':
          const today = this.getTodayDate();
          this.rangeDate = String(today) + ' to ' + String(today);
          break;

        case 'yesterday':
          const yesterday = this.getYesterdayDate();
          this.rangeDate = String(yesterday) + ' to ' + String(yesterday);
          break;

        case 'thisweek':
          const thisweek = this.getThisWeekDate();
          this.rangeDate = String(thisweek.startFormatted) + ' to ' + String(thisweek.endFormatted);
          break;

        case 'lastweek':
          const lastweek = this.getLastWeekDate();
          this.rangeDate = String(lastweek.startFormatted) + ' to ' + String(lastweek.endFormatted);
          break;

        case 'thismonth':
          const thismonth = this.getThisMonthDate();
          this.rangeDate = String(thismonth.startFormatted) + ' to ' + String(thismonth.endFormatted);
          break;

        case 'lastmonth':
          const lastmonth = this.getLastMonthDate();
          this.rangeDate = String(lastmonth.startFormatted) + ' to ' + String(lastmonth.endFormatted);
          break;

        default:
          break;
      }

      this.getClientIdProjectIdReports();
    },

    handleOptionChange() {
      if (this.selectedOption === 'Active') {
        this.isArchive = 0;
        this.getClientIdProjectIdReports();
      } else if (this.selectedOption === 'Archived') {
        this.isArchive = 1;
        this.getClientIdProjectIdReports();
      }
    },

    getClientIdProjectIdReports() {
      this.summaryReports = [];
      this.loading = true;
      if (this.rangeDate.includes('to')) {
        var tempDateRangeStart = this.rangeDate.split(' to ')[0];
        var tempDateRangeEnd = this.rangeDate.split(' to ')[1];

        this.dateRangeStart = this.convertToFirstISO(tempDateRangeStart);
        this.dateRangeEnd = this.convertToSecondISO(tempDateRangeEnd);
      } else {
        this.dateRangeStart = this.convertToFirstISO(this.rangeDate);
        this.dateRangeEnd = this.convertToSecondISO(this.getYesterdayDate());
      }

      store
        .dispatch('project/getClientIdProjectIdReports', { id: this.clientClockifyId, dateRangeStart: this.dateRangeStart, dateRangeEnd: this.dateRangeEnd, selectedProjectIds: [this.projectClockifyId] })
        .then((res) => {
          try {
            this.totalClient = res.data.totals[0]?.totalTime || 0;
            const groupedData = {};
            res.data.timeentries.forEach((el) => {
              const userId = el.userId;
              if (!groupedData[userId]) groupedData[userId] = [];
              groupedData[userId].push(el);
            });

            this.summaryReports = Object.values(groupedData).map((entries) => ({
              userName: entries[0].userName,
              userId: entries[0].userId,
              entries: entries.map((entry) => ({
                description: entry.description,
                timeInterval: entry.timeInterval,
                billable: entry.billable,
                tags: entry.tags,
              })),
              totalDuration: entries.reduce((total, entry) => total + entry.timeInterval.duration, 0),
            }));
          } catch (error) {
            this.totalClient = 0;
          }

          console.log(1);

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.user-badge {
  width: 50%;
  align-content: center;
}

.dataRange {
  width: 100%;
  height: 2.57rem;
  padding: 0.375rem 0rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.358rem;
  text-align: center;
}

.no-result-message {
  font-size: 24px;
  color: #d8d6de;
  text-align: center;
  margin-top: 20px;
}
</style>
