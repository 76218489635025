// helpers.js

export function resolveTeamVariant(tag) {
  const variants = {
    EXCEL: '#77e856',
    WEB: '#4a73e5',
    OTHER: '#80878f',
  };
  return variants[tag] || 'N/A';
}

export function resolveTeamText(tag) {
  const texts = {
    EXCEL: 'EXCEL',
    WEB: 'WEB',
    OTHER: 'OTHER',
  };
  return texts[tag] || 'N/A';
}

export function getShortDescription(selectedOption) {
  const descriptions = {
    Call: '📞 Phone call made',
    Mail: '📧 Email sent',
    Check: '✅ Checked',
    Meeting: '📅 Meeting created',
    Quote: '🧾️ Quote provided',
    Invoice: '💲 Invoice created',
    Lead: '✔️ Lead created',
  };

  if (descriptions.hasOwnProperty(selectedOption)) {
    return descriptions[selectedOption];
  }

  return 'Description not available.';
}

export function getShortVariant(selectedOption) {
  const variants = {
    Call: 'success',
    Mail: 'info',
    Check: 'danger',
    Meeting: 'primary',
    Quote: 'secondary',
    Invoice: 'dark',
    Lead: 'dark',
  };

  if (variants.hasOwnProperty(selectedOption)) {
    return variants[selectedOption];
  }

  return 'Description not available.';
}

export function getTruncatedDescription(description) {
  return description.slice(0, 50) + '...';
}

export function formatTimeAgo(dateTimeString, updatedDateTimeString) {
  const now = new Date();
  const date = new Date(dateTimeString);
  const updatedDate = new Date(updatedDateTimeString);
  const diffInMilliseconds = now - date;
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInSeconds < 15) {
    return `Just now`;
  } else if (diffInSeconds >= 15 && diffInSeconds < 60) {
    const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
    return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago${edited}`;
  } else if (diffInMinutes < 60) {
    const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
    return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago${edited}`;
  } else if (diffInHours < 24) {
    const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
    return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago${edited}`;
  } else {
    const edited = date.getTime() !== updatedDate.getTime() ? ' (Edited)' : '';
    return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago${edited}`;
  }
}

export function formatDateTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  return dateTime.toLocaleDateString('en-UK', options);
}

export function getAvatarText(value) {
  if (value) {
    const words = value.split(' ');
    const initials = words.map((word) => word.charAt(0).toUpperCase());
    return initials.join('');
  } else {
    return 'NA';
  }
}

export function hoursFormat(val) {
  var totalMinutes = Math.floor(val / 60);

  var hours = Math.floor(totalMinutes / 60);
  var minutes = totalMinutes % 60;

  return hours + ' Hours, ' + minutes + ' Minutes';
}

export function getTodayDate() {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Note: January is 0!
  const year = today.getFullYear();
  const todayDate = `${day}-${month}-${year}`;
  return todayDate;
}

export function getYesterdayDate() {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const day = yesterday.toLocaleDateString('en-GB', options).slice(0, 2);
  const month = yesterday.toLocaleDateString('en-GB', options).slice(3, 5);
  const year = yesterday.toLocaleDateString('en-GB', options).slice(6);
  const yesterdayFormatted = `${day}-${month}-${year}`;
  return yesterdayFormatted;
}

export function getThisWeekDate() {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const currentDate = new Date();

  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDate.getDate() - (currentDate.getDay() - 1)); // Pazartesi olarak ayarlanır
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6); // Bu haftanın son günü

  const startDay = startOfWeek.toLocaleDateString('en-GB', options).slice(0, 2);
  const startMonth = startOfWeek.toLocaleDateString('en-GB', options).slice(3, 5);
  const startYear = startOfWeek.toLocaleDateString('en-GB', options).slice(6);
  const startFormatted = `${startDay}-${startMonth}-${startYear}`;

  const endDay = endOfWeek.toLocaleDateString('en-GB', options).slice(0, 2);
  const endMonth = endOfWeek.toLocaleDateString('en-GB', options).slice(3, 5);
  const endYear = endOfWeek.toLocaleDateString('en-GB', options).slice(6);
  const endFormatted = `${endDay}-${endMonth}-${endYear}`;
  return { startFormatted, endFormatted };
}

export function getLastWeekDate() {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const currentDate = new Date();

  const startOfLastWeek = new Date(currentDate);
  startOfLastWeek.setDate(currentDate.getDate() - (currentDate.getDay() - 1) - 7); // Geçen haftanın pazartesi olarak ayarlanır
  const endOfLastWeek = new Date(startOfLastWeek);
  endOfLastWeek.setDate(startOfLastWeek.getDate() + 6); // Geçen haftanın son günü

  const startDay = startOfLastWeek.toLocaleDateString('en-GB', options).slice(0, 2);
  const startMonth = startOfLastWeek.toLocaleDateString('en-GB', options).slice(3, 5);
  const startYear = startOfLastWeek.toLocaleDateString('en-GB', options).slice(6);
  const startFormatted = `${startDay}-${startMonth}-${startYear}`;

  const endDay = endOfLastWeek.toLocaleDateString('en-GB', options).slice(0, 2);
  const endMonth = endOfLastWeek.toLocaleDateString('en-GB', options).slice(3, 5);
  const endYear = endOfLastWeek.toLocaleDateString('en-GB', options).slice(6);
  const endFormatted = `${endDay}-${endMonth}-${endYear}`;
  return { startFormatted, endFormatted };
}

export function getThisMonthDate() {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const currentDate = new Date();

  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  const startDay = startOfMonth.toLocaleDateString('en-GB', options).slice(0, 2);
  const startMonth = startOfMonth.toLocaleDateString('en-GB', options).slice(3, 5);
  const startYear = startOfMonth.toLocaleDateString('en-GB', options).slice(6);
  const startFormatted = `${startDay}-${startMonth}-${startYear}`;

  const endDay = endOfMonth.toLocaleDateString('en-GB', options).slice(0, 2);
  const endMonth = endOfMonth.toLocaleDateString('en-GB', options).slice(3, 5);
  const endYear = endOfMonth.toLocaleDateString('en-GB', options).slice(6);
  const endFormatted = `${endDay}-${endMonth}-${endYear}`;
  return { startFormatted, endFormatted };
}

export function getLastMonthDate() {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const currentDate = new Date();

  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
  const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

  const startDay = startOfMonth.toLocaleDateString('en-GB', options).slice(0, 2);
  const startMonth = startOfMonth.toLocaleDateString('en-GB', options).slice(3, 5);
  const startYear = startOfMonth.toLocaleDateString('en-GB', options).slice(6);
  const startFormatted = `${startDay}-${startMonth}-${startYear}`;

  const endDay = endOfMonth.toLocaleDateString('en-GB', options).slice(0, 2);
  const endMonth = endOfMonth.toLocaleDateString('en-GB', options).slice(3, 5);
  const endYear = endOfMonth.toLocaleDateString('en-GB', options).slice(6);
  const endFormatted = `${endDay}-${endMonth}-${endYear}`;
  return { startFormatted, endFormatted };
}

export function formatDate(value) {
  if (!value) return value;
  const date = new Date(value);
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0');
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date); // Kısa ay adını alır
  const year = date
    .getFullYear()
    .toString()
    .slice(-2);
  return `${day} ${month}  ${year}`;
}

export function getTeamClass(team) {
  switch (team) {
    case 'Systems Tailor Made':
      return 'text-primary';
    case 'Tailor Made Excel':
      return 'text-success';
    case 'Group Tailor Made':
      return 'text-warning';
    default:
      return 'text-secondary';
  }
}

export function convertToFirstISO(dateString) {
  const parts = dateString.split('-');
  const isoDate = new Date(Date.UTC(parts[2], parts[1] - 1, parts[0], 0, 0, 0)).toISOString();
  return isoDate;
}

export function convertToSecondISO(dateString) {
  const parts = dateString.split('-');
  const isoDate = new Date(Date.UTC(parts[2], parts[1] - 1, parts[0], 23, 59, 59)).toISOString();
  return isoDate;
}
