<template>
  <div>
    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-row v-if="projectData != null">
        <b-col cols="12">
          <b-card>
            <div class="d-flex justify-content-end">
              <div class="w-15 mt-2">
                <button
                  :style="{
                    backgroundColor: projectData.archived ? '#8d8c8c' : '#2dc034',
                    color: '#FFFFFF',
                  }"
                  style=" border: none; padding: 5px; cursor: pointer; width:100%"
                  id="archive-control"
                >
                  {{ projectData.archived ? 'Archived' : 'Active' }}
                </button>
                <b-popover target="archive-control" triggers="focus" variant="primary" placement="left">
                  <div class="d-flex flex-column">
                    <button @click="archiveProject(false)" class="mb-25" style="padding:5px 25px;  border:none;background-color:#2dc034; color:#FFFFFF">Active</button>
                    <button @click="archiveProject(true)" class="mb-25" style="padding:5px 25px; border:none;background-color:#8d8c8c; color:#FFFFFF">Archived</button>
                  </div>
                </b-popover>
              </div>
            </div>

            <div class="projectTitle mb-1" @click="isNameEditing = true" v-if="!isNameEditing">{{ projectData.name }}</div>
            <b-input v-else class="mb-1" autofocus v-model="projectData.name" @blur="updateProjectName" @focus="initialDescription = projectData.name" @keyup.enter="updateProjectNameEnter" />

            <div class="cardClass">
              <div class="subTitle">Project Description</div>
              <div class="description" @click="(isDescriptionEditing = true), (editedDescription = projectData.description)" v-if="!isDescriptionEditing">
                <span v-html="projectData.description || 'No Description'"> </span>
              </div>

              <div v-else>
                <quill-editor id="quil-content" style="background-color: white;" v-model="editedDescription" :options="descriptionOption" />

                <div id="description-toolbar" class="d-flex justify-content-end">
                  <button class="ql-bold"></button>
                  <button class="ql-underline"></button>
                  <button class="ql-background" value="#fdff32"></button>
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-link"></button>

                  <b-button @click="updateProjectDescription" variant="primary" style="width: 60px;">
                    Update
                  </b-button>

                  <b-button @click="cancelDescriptionEdit" variant="danger" class="ml-25" style="width: 60px;">
                    Cancel
                  </b-button>
                </div>
              </div>
            </div>

            <div class="cardClass">
              <div class="subTitle">Project Client</div>
              <div style="width:30% !important;">
                <v-select v-model="projectData.client" :taggable="true" :options="clients" :reduce="(val) => val.id" label="name" @input="updateClient" :clearable="false" @change="updateClient"> </v-select>
              </div>
            </div>

            <div class="cardClass">
              <div style="width:30% !important;">
                <div class="subTitle">Starting Date (m/d/Y)</div>
                <b-form-datepicker
                                          :id="'start-date-' + projectData.id.toString()"
                                          placeholder="Add due date"
                                          v-model="projectData.startDate"
                                          :date-format-options="{
                                            day: 'numeric',
                                          month: 'numeric',
                                          year: 'numeric'

                                          }"
                                          @input="updateStartDate()"
                                          locale="en"
                                          label-help=""
                                          :start-weekday="1"
                                          :hide-header="true"
                                          style="border:none; background-color: transparent;"
                                        >
                                        </b-form-datepicker>
                </div>

                    <div style="width:30% !important;" class="mt-1">

                <div class="subTitle">Ending Date (m/d/Y)</div>
                <b-form-datepicker
                                          :id="'end-date-' + projectData.id.toString()"
                                          placeholder="Add due date"
                                          v-model="projectData.endDate"
                                          :date-format-options="{
                                            day: 'numeric',
                                            month: 'numeric',
                                            year: 'numeric'

                                          }"
                                          @input="updateEndDate()"
                                          locale="en"
                                          label-help=""
                                          :start-weekday="1"
                                          :hide-header="true"
                                          style="border:none; background-color: transparent;"
                                        >
                                        </b-form-datepicker>
              </div>
                


            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import projectStoreModule from './projectStoreModule';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue';
import { formatDate, getAvatarText } from '../helpers';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { quillEditor } from 'vue-quill-editor';
import vSelect from 'vue-select';

import { VBPopover } from 'bootstrap-vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BFormDatepicker } from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    VuePerfectScrollbar,
    TodoTaskHandlerSidebar,
    ToastificationContent,
    quillEditor,

    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    flatPickr
  },

  directives: {
    'b-popover': VBPopover,
  },

  props: {
    projectData: {
      type: Object,
      required: true,
    },

    projectClockifyId: {
      type: String,
      required: true,
    },
  },

  data() {
    const descriptionOption = {
      modules: {
        toolbar: '#description-toolbar',
      },
      placeholder: 'Project Description',
    };

    const blankTempTaskData = {
      id: null,
      assignedUser: null,
      additionalAssigneeUser: null,
      dueDate: null,
      description: null,
      projectId: parseInt(router.currentRoute.params.id),
    };

    const tempTaskData = JSON.parse(JSON.stringify(blankTempTaskData));

    const savedActiveTab = localStorage.getItem('activeTab');

    return {
      popoverShow: {},
      initialDescription: null,
      editedDescription: null,
      descriptionOption,
      isNameEditing: false,
      isDescriptionEditing: false,
      loading: true,
      activeTab: savedActiveTab ? parseInt(savedActiveTab, 10) : 2,
      clients: [],
      isTaskHandlerSidebarActive: false,
      blankTempTaskData,
      tempTaskData,

      taskFilters: [
        { title: 'Critical', textColor: '#000000', bgColor: '#f06a6a' },
        { title: 'High', textColor: '#000000', bgColor: '#ec8d71' },
        { title: 'Medium', textColor: '#000000', bgColor: '#f1bd6c' },
        { title: 'Low', textColor: '#000000', bgColor: '#b3df97' },
        { title: 'Not a Priority', textColor: '#FFFFFF', bgColor: '#4573d2' },
      ],
    };
  },

  mounted() {
    this.getClients();
  },
  watch: {},

  methods: {
    formatDate,
    getAvatarText,

    updateProjectName() {
      if (this.initialDescription !== this.projectData.name) {
        this.loading = true;
        this.isNameEditing = false;
        store
          .dispatch('project/updateProjectName', {
            id: this.projectData.id,
            name: this.projectData.name,
          })
          .then((res) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Project Name has been updated',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        this.isNameEditing = false;
      }
    },

    archiveProject(val) {
      this.loading = true;
      this.projectData.archived = val;
      store
        .dispatch('project/archiveProject', {
          id: this.projectData.id,
          archived: this.projectData.archived,
        })
        .then((res) => {
          this.loading = false;
          if (val) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Project has been archived.',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Project has been activated.',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateProjectNameEnter() {
      this.isNameEditing = false;
    },

    updateProjectDescription() {
      this.loading = true;

      store
        .dispatch('project/updateProjectDescription', {
          id: this.projectData.id,
          description: this.editedDescription,
        })
        .then((res) => {
          this.projectData.description = this.editedDescription;
          this.loading = false;
          this.isDescriptionEditing = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Project Description has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateProjectDescriptionEnter() {
      this.isDescriptionEditing = false;
    },

    cancelDescriptionEdit() {
      this.isDescriptionEditing = false;
      this.editedDescription = this.projectData.description;
    },

    getClients() {
      this.loading = true;
      this.clients = [];
      store
        .dispatch('project/getClientAll', { isArchive: 0 })
        .then((res) => {
          this.clients = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateClient() {
      this.loading = true;
      store
        .dispatch('project/updateProjectClient', {
          id: this.projectData.id,
          clientId: this.projectData.client,
        })
        .then((res) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Project Client has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateEndDate() {
      this.loading = true;

      store
        .dispatch('project/updateEndDate', {
          id: this.projectData.id,
          date: this.projectData.endDate,
        })
        .then((res) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Project deadline has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateStartDate() {
      this.loading = true;

      store
        .dispatch('project/updateStartDate', {
          id: this.projectData.id,
          date: this.projectData.startDate,
        })
        .then((res) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Project date has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
span.activeMenu {
  font-weight: 700;
  color: black;
  padding-bottom: 2px;
  box-shadow: inset 0 -2px #6d6e6f;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.passiveMenu {
  font-weight: 400;
  color: #6d6e6f;
  padding-bottom: 2px;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.activeMenu:hover {
  background-color: #f9f8f8;
  padding: 5px 10px 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

span.passiveMenu:hover {
  background-color: #f9f8f8;
  box-shadow: inset 0 -2px #afabac;
  padding: 5px 10px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

.projectTitle {
  font-size: 1.75rem;
  font-weight: 400;
  transition: padding 0.3s;
  border: solid 1px transparent;
  border-radius: 5px;
}

.projectTitle:hover {
  border-color: #ccc;
  background-color: #f9f8f8;
  cursor: pointer;
}

.subTitle {
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
}

.person {
  display: flex;
  align-items: center;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  transition: border 0.3s;
  height: 110px;
  max-width: 300px;
  min-width: 200px;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 10px;
}

.person:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  border-color: #ccc;
}

.contact {
  display: flex;
  align-items: center;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  transition: border 0.3s;
  height: 110px;
  max-width: 300px;
  min-width: 200px;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 10px;
}

.contact:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  border-color: #ccc;
}

.contact-details {
  flex-grow: 1;
}

.description {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px transparent;
  transition: border 0.3s;
  border-radius: 5px;

  // background-color: #00000003;
}

.description:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-color: #ccc;
}

.profile-photo {
  width: 42px;
  height: 42px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: white;
}

.initials {
  text-transform: uppercase;
}

.person-details {
  flex-grow: 1;
}

.name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.position {
  color: #6d6e6f;
  margin-top: 10px;
}
.close-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  color: black;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.person:hover .close-icon {
  opacity: 1;
}

.contact-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  color: black;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.contact:hover .contact-icon {
  opacity: 1;
}

/* TO - DO   */

.todo-header {
  display: flex;
  background-color: #f5f5f5;
}

.header-cell {
  padding: 7px;
  border: 1px solid #e5e5e5;
  font-weight: bold;
}

.task-content {
  display: flex;
  width: 100%;
}

.subtask-content {
  display: flex;
  width: 100%;
  left: 30px;
}

.todo-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.add-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  background-color: #dddddd20;
}

.add-cell:hover {
  background-color: #dddddd40;
  cursor: pointer;
}

.todo-cell:last-child {
  border-right: 1px solid #e5e5e5;
}

.task-name {
  display: flex;
  align-items: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-checkbox {
  position: relative;
}

.custom-checkbox .checkbox-icon {
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
}

.custom-checkbox .checkbox-icon:not(.checked)::before {
  content: '\2713';
  font-size: 10px;
  color: #000;
}

.custom-checkbox .checkbox-icon.checked {
  background-color: #198754;
}

.custom-checkbox .checkbox-icon.checked::before {
  content: '\2713';
  font-size: 14px;
  color: #fff;
}

.no-results {
  text-align: center;
  padding: 20px;
  display: none;
}

.no-results.show {
  display: block;
}

.w-97 {
  width: 97%;
}

.w-47 {
  width: 47%;
}
.w-45 {
  width: 45%;
}

.w-42 {
  width: 42%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}

.w-37 {
  width: 37%;
}

.w-5 {
  width: 5%;
}

.w-4 {
  width: 4%;
}

.w-3 {
  width: 3%;
}
.w-8 {
  width: 8%;
}

.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}

.hover-background:hover {
  background-color: #edeae9;
  cursor: pointer;
  position: relative;
}

.hover-background:hover::after {
  content: '\23F7';
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  color: #bbb;
}

.popoverMenu {
  cursor: pointer;
  padding: 0.5rem;
}

.popoverMenu:hover {
  background-color: rgba(197, 197, 197, 0.05);
  padding: 0.5rem;
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #description-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.cardClass {
  padding: 1.25rem;
}
</style>
