<template>
  <div>
    <b-modal
      id="modal-hide-board"
      @ok="handleOk"
      :title="'Hide Boards'"
      @hidden="closeModal"
      :ok-title="'Hide Selected Boards'"
      :cancel-title="'close'"
      v-model="showHideBoardsModal"
      size="lg"
    >
      <b-overlay :show="loading" rounded="sm" no-fade>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

        <b-table
          :borderless="false"
          :outlined="true"
          :small="true"
          :items="hideBoards"
          :fields="boardHeaders"
          :head-variant="'light'"
          :empty-text="'No boards found'"
          show-empty
          :filter="filter"
          filter-debounce="250"
          :filter-included-fields="filterOn"
        >
          <template #cell(name)="data">
            <div :style="`background-color:${data.item.bgColor}`">
              <strong>{{ data.item.name }}</strong>
            </div>
          </template>

          <template #cell(hide)="data">
            <b-form-checkbox
              :checked="data.item.hide"
              v-model="data.item.hide"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </template>
        </b-table>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  BFormTimepicker,
  VBTooltip,
  BTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import {
  required,
  alphaNum,
  email,
  max,
  max_value,
  min_value,
} from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "HideBoardsModal",
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BFormTimepicker,
    BTooltip,
    vSelect,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showHideBoardsModal: false,
      loading: false,
      hideBoards: [],
      filterOn: ["name"],
      filter: "",
      boardHeaders: [
        {
          key: "name",
          label: "Board Name",
          sortable: false,
          class: "text-start",
        },
        {
          key: "hide",
          label: "Hide",
          sortable: false,
          class: "text-center",
        },
      ],
    };
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.loading = true;

      axiosIns
        .post("multipleHideProjectBoard", { boards: this.hideBoards })
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Successful",
              text: "✔️ Selected Boards has been hidden.",
              icon: "ThumbsUpIcon",
              variant: "success",
            },
          });
          this.$emit("get-projects");
          this.showHideBoardsModal = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeModal() {
      this.showHideBoardsModal = false;
      this.hideBoards = [];
      this.loading = false;
    },
  },
};
</script>

<style></style>
