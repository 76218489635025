<template>
  <b-overlay :show="loading" rounded="sm" no-fade>
    <b-row
      class="d-flex align-items-center"
      style="max-height:40px; flex-wrap: nowrap;"
    >
      <b-col xl="1" lg="1" md="4" cols="4" v-if="$Can('pclockify_sync')">
        <div class="d-flex align-items-center  justify-content-start">
          <b-button
            class="w-100 btnClass"
            style="margin-top:0.300rem; padding-top: 0.525rem; padding-bottom: 0.525rem; display:flex; justify-content:center; align-items:center;"
            variant="outline-warning"
            @click="showDeadlines"
            v-b-tooltip.hover.top="'Deadlines'"
          >
            <feather-icon
              :icon="calendarActive ? 'ColumnsIcon' : 'CalendarIcon'"
              size="20"
            />
            <span
              v-if="
                weekDeadlines.length +
                  passedDeadlines.length +
                  extendedDeadlines.length >
                  0
              "
              class="badge badge-up badge-pill bg-danger notification-badge"
              >{{
                weekDeadlines.length +
                  passedDeadlines.length +
                  extendedDeadlines.length
              }}</span
            >
          </b-button>
        </div>
      </b-col>

      <!-- buttons  -->
      <b-col xl="1" lg="1" md="4" cols="4" v-if="$Can('pclockify_sync')">
        <div
          class="d-flex align-items-center  justify-content-start"
          v-if="!calendarActive"
        >
          <b-button
            class="w-100 btnClass"
            style="margin-top:0.300rem; padding-top: 0.525rem; padding-bottom: 0.525rem; display:flex; justify-content:center; align-items:center;"
            variant="outline-dark"
            @click="getProjectDataFromClockify"
            v-b-tooltip.hover.top="'Get Data From Clockify'"
          >
            <feather-icon icon="DownloadCloudIcon" size="20" />
          </b-button>
        </div>
      </b-col>

      <b-col xl="1" lg="1" md="4" cols="4" v-if="$Can('hide_board')">
        <b-dropdown
          variant="outline-primary"
          v-if="!calendarActive"
          style="margin-top:0.300rem; padding-top: 0.525rem; padding-bottom: 0.525rem;width:100%"
          class="more-options"
        >
          <template #button-content>
            <feather-icon icon="MoreHorizontalIcon" size="16" />
          </template>
          <b-dropdown-item
            @click="openHideBoardsModal"
            class="d-flex align-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              style="margin-right: 0.5rem;"
            >
              <path
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 15h2M3 5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2zm8 7h6m-4-3h4"
              />
            </svg>
            <span>My Hide boards </span>
          </b-dropdown-item>
          <b-dropdown-item
            @click="moreDetails = !moreDetails"
            class="d-flex align-items-center"
          >
            <feather-icon
              :icon="moreDetails ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              size="20"
              style="margin-right: 0.5rem;"
            />
            <span
              >{{ moreDetails ? "Hide More Details" : "Show More details" }}
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>

      <b-col xl="2" lg="2" md="4" cols="4">
        <div class="d-flex align-items-center">
          <v-select
            placeholder="Team"
            v-if="!calendarActive"
            v-model="selectedTeam"
            :options="['Web', 'VBA']"
            :clearable="true"
            style="background-color: white;"
            class="w-100 m-25"
          />
        </div>
      </b-col>

      <b-col xl="2" lg="2" md="4" cols="4">
        <div class="d-flex align-items-center">
          <v-select
            v-if="!calendarActive"
            v-model="selectedOption"
            :options="deckView ? showOptions2 : showOptions"
            :clearable="false"
            style="background-color: white;"
            class="w-100 m-25"
            @input="handleOptionChange"
          />
        </div>
      </b-col>

      <b-col xl="2" lg="2" md="4" cols="4">
        <div class="d-flex align-items-center">
          <v-select
            v-if="!calendarActive"
            v-model="selectedDeckOption"
            :options="deckOptions"
            :clearable="false"
            :reduce="(val) => val.name"
            label="name"
            style="background-color: white;"
            class="w-100 m-25"
            @input="getProjects"
          />
        </div>
      </b-col>

      <b-col
        xl="3"
        lg="3"
        md="6"
        cols="12"
        v-if="$Can('projects_add_btn') && !calendarActive"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchText"
            class="d-inline-block mr-1"
            cle
            placeholder="Search..."
          />
          <b-button variant="primary" router-link to="/projects/project-add">
            <span class="text-nowrap"> Create Project </span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <div v-if="filteredProjects.length > 0" class="mt-2" :key="componentKey">
      <column-view
        v-if="deckView && !calendarActive"
        :week-deadlines="weekDeadlines"
        :passed-deadlines="passedDeadlines"
        :extended-deadlines="extendedDeadlines"
        :more-details="moreDetails"
        :deck-options="deckOptions.filter((e) => e.name != 'All')"
        :filtered-projects="filteredProjects"
        @get-projects="getProjects"
        @get-deadlines="getDeadlines"
      ></column-view>

      <calendar
        v-else-if="calendarActive"
        :deadlines="currentDeadlines"
        :more-details="moreDetails"
        :deck-options="deckOptions.filter((e) => e.name != 'All')"
        :filtered-projects="filteredProjects"
        @get-projects="getProjects"
        @get-deadlines="getDeadlines($event)"
      ></calendar>
    </div>

    <div class="text-center text-muted" v-else>
      <h2 class="text-muted mt-5">There are no projects</h2>
    </div>

    <HideBoardsModal ref="refBoardsModal" @get-projects="getProjects" />
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import projectStoreModule from "./projectStoreModule";
import vSelect from "vue-select";
import VueContext from "vue-context";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BBreadcrumb } from "bootstrap-vue";
import { formatDate, getTeamClass } from "../helpers";
import BoxView from "./BoxView.vue";
import ColumnView from "./ColumnView.vue";
import { debounce } from "lodash";
import Calendar from "./Calendar.vue";
import HideBoardsModal from "./HideBoardsModal.vue";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    VueContext,
    BBreadcrumb,
    ToastificationContent,
    BoxView,
    ColumnView,
    Calendar,
    HideBoardsModal,
  },

  setup() {
    const PROJECT_APP_STORE_MODULE_NAME = "project";
    // Register module
    if (!store.hasModule(PROJECT_APP_STORE_MODULE_NAME))
      store.registerModule(PROJECT_APP_STORE_MODULE_NAME, projectStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROJECT_APP_STORE_MODULE_NAME))
        store.unregisterModule(PROJECT_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      searchText: "",
      companiesURL: store.state.app.companiesURL,
      loading: true,
      isArchive: 0,
      projects: [],
      selectedOption: "Active",
      selectedDeckOption: "All",
      showOptions: ["Active", "Archived"],
      showOptions2: ["Active", "Archived", "Hidden"],
      deckOptions: [],
      windowHeight: window.innerHeight,
      deckView: true,
      moreDetails: false,
      calendarActive: false,
      currentDeadlines: [],
      weekDeadlines: [],
      passedDeadlines: [],
      extendedDeadlines: [],
      showAdditional: false,
      componentKey: 0,
      additionalCountThisWeek: 0,
      selectedTeam: null,
    };
  },

  mounted() {
    this.getProjects();
    this.debouncedGetWindowHeight = debounce(this.getWindowHeight, 300);
    window.addEventListener("resize", this.debouncedGetWindowHeight);
  },
  beforeDestroy() {
    // window.removeEventListener("resize", this.getWindowHeight);
    window.removeEventListener("resize", this.debouncedGetWindowHeight);
  },

  watch: {
    // filteredProjects: {
    //   handler: function(val, before) {
    //     console.log(val);
    //   },
    //   deep: true,
    // },
  },

  methods: {
    getWindowHeight() {
      this.windowHeight = window.innerHeight;
    },

    formatDate,
    getTeamClass,

    getProjects() {
      this.loading = true;

      store
        .dispatch("project/getProjectBoards", [])
        .then((res) => {
          this.deckOptions = res.data;

          this.deckOptions.unshift({
            name: "All",
            hide: false,
            bgColor: "00000",
            color: "00000",
          });
          +store
            .dispatch("project/getProjects", {
              isArchive: this.isArchive,
              isHidden: this.deckView ? this.selectedOption : null,
              selectedStatus: this.selectedDeckOption,
            })
            .then((res) => {
              this.projects = res.data;
              this.getDeadlines();
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    handleOptionChange() {
      if (this.selectedOption === "Active") {
        this.isArchive = 0;
        this.getProjects();
      } else if (this.selectedOption === "Archived") {
        this.isArchive = 1;
        this.getProjects();
      } else if (this.selectedOption === "Hidden") {
        this.getProjects();
      }
    },

    redirectPreview(_id) {
      this.$router.push({ name: "project-preview", params: { id: _id } });
    },

    getProjectDataFromClockify() {
      this.loading = true;
      store
        .dispatch("project/getProjectDataFromClockify")
        .then((res) => {
          this.getProjects();
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    showDeadlines() {
      this.calendarActive = !this.calendarActive;
    },

    openHideBoardsModal() {
      this.$refs.refBoardsModal.showHideBoardsModal = true;
      const hideBoards = this.deckOptions.filter((b) => b.name !== "All");
      this.$refs.refBoardsModal.hideBoards = hideBoards;
    },

    getDeadlines(showDeadline) {
      if (showDeadline) {
        this.showAdditional = showDeadline;
      }
      store
        .dispatch("project/getDeadlines", {
          showAdditional: this.showAdditional,
        })
        .then((res) => {
          (this.additionalCountThisWeek = res.data.additionalCountThisWeek),
            (this.currentDeadlines = res.data.allDeadlines);
          this.weekDeadlines = res.data.weekDeadlines;
          this.passedDeadlines = res.data.passedDeadlines;
          this.extendedDeadlines = res.data.extendedDeadlines;

          this.componentKey++;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
  },

  computed: {
    filteredProjects() {
      if (this.selectedTeam) {
        const matchingProjects = [];
        for (const project of this.projects) {
          const projectTeam = project.team == this.selectedTeam;

          if (projectTeam) {
            matchingProjects.push(project);
          }
        }

        return matchingProjects;
      }
      if (!this.searchText) {
        return this.projects;
      }

      const lowercaseSearchText = this.searchText.toLowerCase();

      let matchingProjects = [];

      for (const project of this.projects) {
        const projectNameIncludesText = project.name
          .toLowerCase()
          .includes(lowercaseSearchText);
        const clientNameIncludesText =
          project.client &&
          project.client.name &&
          project.client.name.toLowerCase().includes(lowercaseSearchText);

        if (projectNameIncludesText || clientNameIncludesText) {
          matchingProjects.push(project);
        }
      }

      // En son eşleşen projeyi bul
      let lastMatchingProject = null;
      for (const project of matchingProjects) {
        lastMatchingProject = project;
      }

      return lastMatchingProject ? matchingProjects : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.card:hover {
  // transform: scale(1.01);
  box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.15);
  // cursor: pointer;
}

.card {
  background-color: #fff;
  // max-width: 360px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0rem;
  border: solid 1px #00000025;
}
.card .banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  height: 125px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  margin: 10px 20px 0px 20px;
}

.card h2.name {
  text-align: center;
  margin: 0;
  padding: 0rem 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: normal;
  font-size: 1.225rem;
}
.card .title {
  color: #a0a0a0;
  font-size: 0.85rem;
  text-align: center;
  padding: 0 2rem 1.2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.card .actions {
  padding: 0 2rem 1.2rem;
  display: flex;
  flex-direction: column;
  order: 99;
}
.card .actions .task-info {
  padding: 0 0 1rem;
  display: flex;
}
.card .actions .task-info h2 {
  text-align: center;
  width: 50%;
  margin: 0;
  box-sizing: border-box;
}
.card .actions .task-info h2 a {
  text-decoration: none;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  transition: background-color 100ms ease-in-out;
}
.card .actions .task-info h2 a span {
  color: #73c0d2;
  font-weight: bold;
  transform-origin: bottom;
  transform: scaleY(1.3);
  transition: color 100ms ease-in-out;
}
.card .actions .task-info h2 a small {
  color: #afafaf;
  font-size: 0.85rem;
}

.card .desc {
  text-align: justify;
  padding: 0 2rem 2.5rem;
  order: 100;
}

.strikethrough {
  text-decoration: line-through;
}

.custom-checkbox {
  position: relative;
}

/* Style the custom checkbox container */
.custom-checkbox .checkbox-icon {
  width: 45px;
  height: 42px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
}
/* Style the unchecked state of the checkbox icon */
.custom-checkbox .checkbox-icon:not(.checked)::before {
  font-size: 35px;
  color: #ccc; /* Gray color for the tick when unchecked */
}

/* Style the checked state of the checkbox icon */
.custom-checkbox .checkbox-icon.checked {
  background-color: #194c87; /* Green background when checked */
  color: white;
}

/* Style the tick icon (a simple checkmark) when checked */
.custom-checkbox .checkbox-icon.checked::before {
  font-size: 35px;
  color: #fff; /* White color for the tick when checked */
}
.notification-badge {
  margin-top: 5% !important;
  margin-right: 5% !important;
  height: 25px;
  width: 25px;
  font-size: medium;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnClass {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
</style>

<style>
.more-options .btn.dropdown-toggle {
  max-height: 37px !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "~vue-context/dist/css/vue-context.css";
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
