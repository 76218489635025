<template>
  <div>
    <b-modal id="modal-add-member" @ok="handleOk" @show="clearData" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Add" centered title="Add Member">
      <validation-observer ref="modalAddMember">
        <b-form>
          <b-form-group>
            <label for="userName">Select a User</label>
            <validation-provider name="User" #default="{ errors }" rules="required">
              <v-select id="userName" label="fullName" v-model="tempMemberName" :reduce="(val) => val" :options="userProp" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="memberRole">Role Name</label>
            <b-form-input id="memberRole" v-model="tempMemberRole" />
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal, BForm, BFormInput, BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  props: {
    userProp: Array,
  },

  data() {
    return {
      tempMemberName: null,
      tempMemberRole: null,
      tempData: null,
    };
  },

  methods: {
    clearData() {
      this.tempMemberName = null;
      this.tempMemberRole = null;
      this.tempData = null;
    },

    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();

      this.$refs.modalAddMember.validate().then((success) => {
        if (success) {
          this.tempData = {
            userId: this.tempMemberName.id,
            userTitle: this.tempMemberName.fullName,
            userRole: this.tempMemberRole,
          };

          this.$emit('postUserData', this.tempData);
          this.$bvModal.hide('modal-add-member');
        }
      });
    },
  },
};
</script>
