<template>
  <div class="demo-app">
    <b-card class="calendar-card demo-app-main">
      <FullCalendar class="demo-app-calendar" :options="calendarOptions">
        <template v-slot:eventContent="arg">
          <div class="event-content">
            <span class="event-time">{{ arg.timeText }}</span>
            <div class="event-details">
              <span
                class="event-title"
                v-b-tooltip.hover
                :title="arg.event.title"
                >{{ arg.event.title }}</span
              >

              <div class="d-flex justify-content-between align-items-center">
                <span
                  class="event-client"
                  v-b-tooltip.hover
                  :title="arg.event.extendedProps.client"
                  >{{ arg.event.extendedProps.client }}</span
                >

                <feather-icon
                  v-if="isDeadlinePassed(arg.event.startStr)"
                  icon="AlertCircleIcon"
                  size="16"
                  style="color: red"
                  v-b-tooltip.hover
                  title="Event deadline has passed"
                />
              </div>
            </div>
          </div>
        </template>
      </FullCalendar>
    </b-card>
    <div v-if="isProjectSidebarActive">
      <project-sidebar
        :is-project-sidebar-active="isProjectSidebarActive"
        :deck-options="deckOptions"
        :project-modal-id="projectModalId"
        v-model="isProjectSidebarActive"
        @emit-get-projects="[]"
        @get-deadlines="getDeadlines"
      />
    </div>
    <div>
      <b-modal
        size="lg"
        title="All Deadlines"
        v-model="isSidebar"
        @hide="updateSidebar"
        @ok="updateSidebar"
        @cancel="updateSidebar"
        @close="updateSidebar"
      >
        <div class="demo-app-sidebar">
          <div class="demo-app-sidebar-section">
            <table class="table mt-3">
              <thead>
                <tr>
                  <th scope="col" style="min-width: 150px !important;">Date</th>
                  <th scope="col" style="min-width: 150px !important;">
                    Title
                  </th>
                  <th scope="col" style="min-width: 150px !important;">
                    Client
                  </th>
                  <th scope="col" style="min-width: 150px !important;">Type</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="event in paginatedEvents"
                  :key="event.id"
                  :class="{
                    'deadline-row': true,
                    'passed-deadline': isDeadlinePassed(event.startStr),
                  }"
                  @click="openProject(event.extendedProps.projectId)"
                >
                  <td style="min-width: 150px !important;">
                    {{ event.startStr }}
                  </td>
                  <td style="min-width: 150px !important;">
                    {{ event.title }}
                  </td>
                  <td style="min-width: 150px !important;">
                    {{ event.extendedProps.client }}
                  </td>
                  <td style="min-width: 150px !important;">
                    {{
                      event.extendedProps.type == "delivery"
                        ? isDeadlinePassed(event.startStr)
                          ? "Overdue Delivery📌"
                          : "Delivery📌"
                        : "Additional"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Pagination Controls -->
            <div class="d-flex justify-content-between align-items-center">
              <div class="pagination-info">
                Showing {{ paginatedEvents.length }} of
                {{ currentEvents.length }} items. Page {{ currentPage }} of
                {{ totalPages }}.
              </div>

              <nav aria-label="Page navigation">
                <ul class="pagination justify-content-end">
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === 1 }"
                  >
                    <a
                      class="page-link"
                      href="#"
                      @click.prevent="goToPage(currentPage - 1)"
                      >Previous</a
                    >
                  </li>
                  <li
                    class="page-item"
                    v-for="page in totalPages"
                    :key="page"
                    :class="{ active: page === currentPage }"
                  >
                    <a
                      class="page-link"
                      href="#"
                      @click.prevent="goToPage(page)"
                      >{{ page }}</a
                    >
                  </li>
                  <li
                    class="page-item"
                    :class="{ disabled: currentPage === totalPages }"
                  >
                    <a
                      class="page-link"
                      href="#"
                      @click.prevent="goToPage(currentPage + 1)"
                      >Next</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from "bootstrap-vue";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ProjectSidebar from "./ProjectSidebar.vue";

export default {
  components: {
    ProjectSidebar,
    FullCalendar, // make the <FullCalendar> tag available
    ToastificationContent,
  },

  props: {
    deadlines: {
      type: Array,
      required: true,
    },

    filteredProjects: {
      type: Array,
      required: true,
    },
    deckOptions: {
      type: Array,
      required: true,
    },

    moreDetails: {
      required: false,
    },
    isAppSidebarActive: {
      type: Boolean,
      required: false,
    },
  },

  directives: {
    "b-tooltip": VBTooltip,
  },

  data: function() {
    return {
      isSidebar: false,
      projectModalId: null,
      isProjectSidebarActive: false,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today AdditionalDeadlinesButton AllDeadlinesButton",
          center: "title",
          right: "dayGridMonth,dayGridWeek,dayGridDay",
        },
        customButtons: {
          AdditionalDeadlinesButton: {
            text: "Additional Deadlines", // custom button teext
            click: this.handleAdditionalDeadlines, //custom button function.
          },
          AllDeadlinesButton: {
            text: "Show All Deadlines",
            click: this.handleShowAllDeadlines,
          },
        },
        initialView: "dayGridWeek",
        initialEvents: this.deadlines, // alternatively, use the `events` setting to fetch from a feed
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventDidMount: function(info) {
          const eventElement = info.el;
          const eventColor = info.event._def.ui.borderColor ?? "#3788d8";

          eventElement.style.setProperty("--event-border-color", eventColor);
          eventElement.classList.add("event-border");
        },
      },
      currentEvents: [],
      showAdditional: false,
      currentPage: 1,
      itemsPerPage: 10,
    };
  },

  computed: {
    totalPages() {
      return Math.ceil(this.currentEvents.length / this.itemsPerPage);
    },

    paginatedEvents() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.currentEvents.slice(start, end);
    },
  },

  methods: {
    updateSidebar() {
      this.isSidebar = false;
    },

    getDeadlines() {
      store
        .dispatch("project/getDeadlines", {
          showAdditional: this.showAdditional,
        })
        .then((res) => {
          (this.additionalCountThisWeek = res.data.additionalCountThisWeek),
            (this.currentDeadlines = res.data.allDeadlines);
          this.weekDeadlines = res.data.weekDeadlines;
          this.passedDeadlines = res.data.passedDeadlines;
          this.extendedDeadlines = res.data.extendedDeadlines;

          this.componentKey++;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    handleEventClick({ event }) {
      this.projectModalId = event.extendedProps.projectId;
      this.isProjectSidebarActive = true;
    },

    handleEvents(events) {
      this.currentEvents = events;
    },

    openProject(id) {
      this.projectModalId = id;
      this.isProjectSidebarActive = true;
      this.updateSidebar();
    },

    isDeadlinePassed(deadline) {
      // Convert deadline to a Date object
      const deadlineDate = new Date(deadline);
      // Get the current date
      const currentDate = new Date();
      // Set hours, minutes, seconds, and milliseconds of current date to midnight
      currentDate.setHours(0, 0, 0, 0);
      // Compare deadlineDate with currentDate
      return deadlineDate < currentDate;
    },
    handleAdditionalDeadlines() {
      this.showAdditional = !this.showAdditional;
      const button = document.querySelector(
        ".fc-AdditionalDeadlinesButton-button"
      );
      if (button) {
        button.classList.toggle("clicked", this.showAdditional); // Sınıfı ekleyip çıkar
      }
      this.$emit("get-deadlines", this.showAdditional);
    },
    handleShowAllDeadlines() {
      this.isSidebar = !this.isSidebar;
    },

    goToPage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
  },
};
</script>

<style lang="css" scoped>
h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b {
  /* used for event dates/times */
  margin-right: 3px;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-sidebar {
  width: 100%;
  line-height: 1.5;
  /* background: #eaf9ff; */
  /* border: 1px solid #d3e2e8;
  border-radius: 5px; */
}

.demo-app-sidebar-section {
  padding: 0;
}

.demo-app-main {
  flex-grow: 1;
  padding: 1em;
  max-height: 90%;
}

.fc {
  /* the calendar root */
  max-width: 1400px;
  margin: 0 auto;
}
.fc >>> .fc-event-main {
  padding: 0.9rem 0.5rem;
}
.pin-icon {
  margin-right: 5px;
  align-self: flex-start; /* Align pin icon to the top */
}

.deadline-row {
  cursor: pointer;
}

.passed-deadline {
  background-color: rgb(237, 136, 136);
}

.event-content {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
}

.event-time {
  font-weight: bold;
  margin-right: 5px;
}

.event-details {
  display: flex;
  flex-direction: column;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  gap: 5px;
}

.event-title {
  font-weight: bold;
  color: #2c3e50;
  word-break: break-all;
  /* white-space: wrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #b8bdc1;
}

.event-client {
  color: #555;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<style>
.fc-AdditionalDeadlinesButton-button {
  background-color: white !important;
  color: #2c3e50 !important;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold !important;
  outline: none !important;

  transition: all 0.2s;
}

.fc-AdditionalDeadlinesButton-button.clicked {
  background-color: #2c3e50 !important;
  color: white !important;
  outline: none !important;
}

.pagination-info {
  margin-top: 10px;
  text-align: center;
}

.pagination {
  margin-top: 20px;
}

.fc-event {
  background-color: white !important;
}

.event-border {
  border: 2px solid var(--event-border-color) !important;
}

.calendar-card .card-body {
  padding-top: 0.5rem;
}

@media (min-width: 1025px) {
  .modal-lg,
  .modal-xl {
    max-width: 1200px;
  }
}
</style>
