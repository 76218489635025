<template>
  <b-overlay :show="loading" rounded="sm" no-fade class="">
    <div class="mb-1">
      <b-row>
        <b-col cols="12" md="3">
          <div class="d-flex align-items-center">
            <v-select v-model="selectedOption" :options="showOptions" :clearable="false" style="background-color: white;" class="w-100" @input="handleOptionChange" />
          </div>
        </b-col>

        <!-- <b-col cols="12" md="9">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-if="filteredProjects.length > 0" v-model="searchText" class="d-inline-block mr-1" cle placeholder="Search..." />
            <b-button variant="primary" router-link to="/projects/project-add">
              <span class="text-nowrap"> Create Project </span>
            </b-button>
          </div>
        </b-col> -->
      </b-row>
    </div>

    <div class="row" v-if="filteredProjects.length > 0">
      <b-col cols="12" sm="12" md="12" lg="6" xl="6" v-for="project in filteredProjects" :key="project.id">
        <div class="card" @click="redirectPreview(project.id)">
          <div class="banner" :style="'background-image: url(' + companiesURL + project.logo + ');'"></div>
          <h2 :class="{ name: true, 'mt-2': true, strikethrough: isArchive === 1 }">
            {{ project.name }}
          </h2>
          <div class="title">
            <!-- <span :class="getTeamClass(project.team)">{{ project.team }} </span><br /><strong>{{ formatDate(project.startDate) }}</strong> -->
          </div>
          <div class="actions">
            <div class="task-info">
              <h2>
                <a
                  ><span>{{ project.totalTask }}</span
                  ><small>Tasks</small></a
                >
              </h2>
              <h2>
                <a
                  ><span>{{ project.completedTask }}</span
                  ><small>Done</small></a
                >
              </h2>
            </div>
            <div class="task-btn">
              <div class="progress-wrapper">
                <b-progress :max="project.totalTask" class="progress-bar-info">
                  <b-progress-bar :value="project.completedTask" :label="`${Math.round((project.completedTask / project.totalTask) * 100)}%`" variant="success" />
                </b-progress>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </div>

    <div class="text-center text-muted" v-else><h2 class="text-muted mt-5">There are no projects</h2></div>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import router from '@/router';
import { onUnmounted } from '@vue/composition-api';
import projectStoreModule from './projectStoreModule';
import vSelect from 'vue-select';
import VueContext from 'vue-context';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BBreadcrumb } from 'bootstrap-vue';
import { formatDate, getTeamClass } from '../helpers';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    VueContext,
    BBreadcrumb,
    ToastificationContent,
  },


  props: {
    clientId: {
    type: Number,
  },

},



  data() {
    return {
      searchText: '',
      companiesURL: store.state.app.companiesURL,
      loading: true,
      isArchive: 0,
      projects: [],
      selectedOption: 'Active',
      showOptions: ['Active', 'Archived'],
      buttonLabel: 'Get Data From Clockify',
    };
  },

  mounted() {
    this.getClientIdProjects();
  },

  watch: {},

  methods: {
    formatDate,
    getTeamClass,

    getClientIdProjects() {
      const _id = parseInt(this.clientId ? this.clientId : router.currentRoute.params.id);
      this.loading = true;
      this.projects = [];
      store
        .dispatch('project/getClientIdProjects', { isArchive: this.isArchive, id: _id })
        .then((res) => {
          this.projects = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    handleOptionChange() {
      if (this.selectedOption === 'Active') {
        this.isArchive = 0;
        this.getClientIdProjects();
      } else if (this.selectedOption === 'Archived') {
        this.isArchive = 1;
        this.getClientIdProjects();
      }
    },

    redirectPreview(_id) {
      this.$router.push({ name: 'project-preview', params: { id: _id } });
    },
  },

  computed: {
    filteredProjects() {
      if (!this.searchText) {
        return this.projects;
      }
      const lowercaseSearchText = this.searchText.toLowerCase();
      return this.projects.filter((project) => project.name.toLowerCase().includes(lowercaseSearchText));
    },
  },
};
</script>

<style lang="scss" scoped>
.card:hover {
  // transform: scale(1.01);
  box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.card {
  background-color: #fff;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0rem;
  border: solid 1px #00000025;
}
.card .banner {
  // background-image: url('~@/assets/images/placeholder.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  // background-color: #00000010;
  margin: 10px 40px 0px 40px;

  //top - right - bottom - left

  // border-bottom: solid 1px #00000020;
}

.card h2.name {
  text-align: center;
  margin: 0;
  padding: 0rem 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: normal;
  font-size: 1.225rem;
}
.card .title {
  color: #a0a0a0;
  font-size: 0.85rem;
  text-align: center;
  padding: 0 2rem 1.2rem;
}
.card .actions {
  padding: 0 2rem 1.2rem;
  display: flex;
  flex-direction: column;
  order: 99;
}
.card .actions .task-info {
  padding: 0 0 1rem;
  display: flex;
}
.card .actions .task-info h2 {
  text-align: center;
  width: 50%;
  margin: 0;
  box-sizing: border-box;
}
.card .actions .task-info h2 a {
  text-decoration: none;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  transition: background-color 100ms ease-in-out;
}
.card .actions .task-info h2 a span {
  color: #73c0d2;
  font-weight: bold;
  transform-origin: bottom;
  transform: scaleY(1.3);
  transition: color 100ms ease-in-out;
}
.card .actions .task-info h2 a small {
  color: #afafaf;
  font-size: 0.85rem;
}

.card .desc {
  text-align: justify;
  padding: 0 2rem 2.5rem;
  order: 100;
}

.strikethrough {
  text-decoration: line-through;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~vue-context/dist/css/vue-context.css';
</style>
