<template>
  <b-row>
    <div class="custom-column" v-for="project in filteredProjects" :key="project.id">
      <div class="card" @click="redirectPreview(project.id)">
        <div class="banner" :style="'background-image: url(' + companiesUrl + project.logo + ');'"></div>
        <!-- <h2 class="name mt-2">{{ project.name }}</h2> -->

        <h2 :class="{ name: true, 'mt-2': true, strikethrough: isArchive === 1 }">
          {{ project.name }}
        </h2>

        <div class="title">
          <small>{{ project.client ? project.client.name : 'NO CLIENT' }}</small>
        </div>
        <div class="actions">
          <div class="task-info">
            <h2>
              <a
                ><span>{{ project.totalTask }}</span
                ><small>Tasks</small></a
              >
            </h2>
            <h2>
              <a
                ><span>{{ project.completedTask }}</span
                ><small>Done</small></a
              >
            </h2>
          </div>
          <div class="text-center">
            <div v-if="project.totalTask > 0" style="height: 1.5rem;">
              <b-progress :max="project.totalTask">
                <b-progress-bar :value="project.completedTask" :label="`${Math.round((project.completedTask / project.totalTask) * 100)}%`" variant="primary" />
              </b-progress>
            </div>
            <div v-else style="height: 1.5rem;" class="text-muted">No tasks available</div>
          </div>
        </div>
      </div>
    </div>
  </b-row>
</template>

<script>
import { BCard, BCol } from 'bootstrap-vue';

export default {
  components: {
    BCol,
    BCard,
  },

  props: {
    filteredProjects: {
      type: Array,
      required: true,
    },
    companiesUrl: {
      type: String,
      required: true,
    },
    isArchive: {
      type: Number,
      required: true,
    },
  },
  methods: {
    redirectPreview(_id) {
      this.$router.push({ name: 'project-preview', params: { id: _id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.card:hover {
  // transform: scale(1.01);
  box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.card {
  background-color: #fff;
  // max-width: 360px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0rem;
  border: solid 1px #00000025;
}
.card .banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  height: 125px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  margin: 10px 20px 0px 20px;
}

.card h2.name {
  text-align: center;
  margin: 0;
  padding: 0rem 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: normal;
  font-size: 1.225rem;
}
.card .title {
  color: #a0a0a0;
  font-size: 0.85rem;
  text-align: center;
  padding: 0 2rem 1.2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.card .actions {
  padding: 0 2rem 1.2rem;
  display: flex;
  flex-direction: column;
  order: 99;
}
.card .actions .task-info {
  padding: 0 0 1rem;
  display: flex;
}
.card .actions .task-info h2 {
  text-align: center;
  width: 50%;
  margin: 0;
  box-sizing: border-box;
}
.card .actions .task-info h2 a {
  text-decoration: none;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  transition: background-color 100ms ease-in-out;
}
.card .actions .task-info h2 a span {
  color: #73c0d2;
  font-weight: bold;
  transform-origin: bottom;
  transform: scaleY(1.3);
  transition: color 100ms ease-in-out;
}
.card .actions .task-info h2 a small {
  color: #afafaf;
  font-size: 0.85rem;
}

.card .desc {
  text-align: justify;
  padding: 0 2rem 2.5rem;
  order: 100;
}

.strikethrough {
  text-decoration: line-through;
}
</style>
